import React, { useEffect, useRef, useState, useCallback } from "react";

import ZoneDates from "./zone-dates";
import { graphql, useStaticQuery } from "gatsby"
import { GetSection } from "./functions/getSection";
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import useCopyToClipboard from "./functions/useCopyToClipboard";
import TimezoneUrlGeneratorModal from "./timezone-url-generator-modal";

// determines the nearest section to snap to when clicking/dragging the selector 
function nearestSection(array, value) {  
        return array.reduce((p, n) => (Math.abs(p) > Math.abs(n - value) ? n - value : p), Infinity) + value;
}

export function sortByOrder(timezoneList) {
    return timezoneList.sort((a,b)=> {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    })
} 

export function sortByOffset(timezoneList, isDescending=false){
    return timezoneList.sort((a,b)=>{
        if (a.node.offset < b.node.offset) {
            return isDescending ? 1:-1;
        }
        if (a.node.offset > b.node.offset) {
            return isDescending ? -1 : 1;
        }
        return 0;
    })
}

    
const TimezoneConverter = props => {


    const zoneRef = useRef(null);
    const [zoneWidth, setZoneWidth]=useState();
    const [zoneTop, setZoneTop]=useState();
    const [zoneHeight, setZoneHeight]=useState();
    const [timeMouseSelected, setTimeMouseSelected] = useState();

    useEffect(()=>{
        // Get the properties of the entire timezone converter
        setZoneWidth(zoneRef.current.offsetWidth);
        setZoneTop(zoneRef.current.offsetTop);
        setZoneHeight(zoneRef.current.offsetHeight);

        // initialize the line position to be the current date/time
        if(mousePosition === undefined){
            setPositionSelected(getTimeSelected())
        }
    }, [zoneWidth, zoneTop, zoneHeight ] )

    const updateZoneProperties=()=>{
         // Get the properties of the entire timezone converter
        setZoneWidth(zoneRef.current.offsetWidth);
        setZoneTop(zoneRef.current.offsetTop);
        setZoneHeight(zoneRef.current.offsetHeight);
        setPositionSelected(getTimeSelected(true))

    }



  useEffect(()=>{
    if (typeof document !== "undefined"){
      window.addEventListener("resize", updateZoneProperties);
      return ()=> window.removeEventListener("resize", updateZoneProperties);
    }
  });
  


    const [currrentDate, setCurrentDate] = useState(new Date())
    
    const [positionSelected, setPositionSelected] = useState({position: 0, time: new Date()});
    
    const [mousePosition, setMousePosition] = useState();
    
    const [isMouseDown, setIsMouseDown] = useState(false)

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedIdPosition, setSelectedIdPosition]=useState();

    const [timezoneList, setTimezoneList] = useState()
    //build the default timezones and custom names
    const defaultFilteredKeys = [
        //Costa Rica
        {key: 11, name: "(UTC-06:00) Costa Rica Time"},
        //Panama
        {key: 15, name: "(UTC-05:00) Panama Time"},
        // UTC
        {key:37, name:""}, 
        // EST/EDT
        {key:16, name:""}, 
        //Central Standard Time
        {key:12, name:""}, 
        //Mountain Standard Time
        {key:10, name:""}, 
        // Pacific Standard Time
        {key:7, name:""}, 
        // Japan Standard Time
        {key:90, name:""}, 
    ]

    const [showGeneratorModal, setShowGeneratorModal] = useState(false);

    // 👇 Using our custom hook
    const copyToClipboard = useCopyToClipboard();

    // contains the position/time
    let sectionTime=[];

    // Time Format
    const timeOptions = { month: 'long', day: 'numeric', year: 'numeric' };    

    
    // retrieve the json file
    const allTimezonesQuery = useStaticQuery(graphql`
    query  {
        allTimezonesJson {
            edges {
                node {
                    abbr
                    isdst
                    key
                    offset
                    text
                    utc
                    value
                    dstOffset
                    dstAbbr
                    dstValue
                    dstText
                }
            }
        }
    }
  `)


    const allTimezonesQueryEdges = allTimezonesQuery.allTimezonesJson.edges

    // Retrieves the default timezone from the parameter or the set default if theres no parameter
    const DefaultTimezoneList = ()=>{
         if (typeof window !== 'undefined') {
        // get the url params and assemble it for the timezone
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.size > 0){
                const getTimezoneParams = urlParams.getAll("timezone")
                const getSplitValues = getTimezoneParams.map(item=>{
                    const getPairs = item.split(",");
                    return getPairs
                })
                let getCustomTimezoneList=[]
                let filteredKeys = []
                let id = 1;
                let order= 0;
                getSplitValues.map(item=>{
                    
                    const getKey = parseInt(item[0]);
                    const getTimezone = allTimezonesQuery.allTimezonesJson.edges.filter(timezone=>timezone.node.key === getKey)
                    
                    getCustomTimezoneList.push({id: id, order: order, key: getKey, name:item[1], timezone: getTimezone[0].node})
                    filteredKeys.push(Number(item[0]))
                    id++;
                    order++;
                })
                const sorted = sortByOrder(getCustomTimezoneList)
                
                setTimezoneList(sorted)
                return sorted

            }
            else if(urlParams.size === 0){
                let defaultTimezoneList=[]
                let id = 1;
                let order= 0;

                const getFilteredTimezones = allTimezonesQuery.allTimezonesJson.edges.filter(item=>{
                    const node = item.node.key;
                    const findFilteredKeys = defaultFilteredKeys.find(x=>x.key === node) 
                    return defaultFilteredKeys.includes(node)
                })

                const sorted = sortByOffset( getFilteredTimezones,true)

                sorted.map(item=>{
                    const getCustomName = defaultFilteredKeys.find(x=>x.key === item.node.key).name;
                    defaultTimezoneList.push({id: id, order: order, key: item.node.key, name:getCustomName?getCustomName:"", timezone: item.node})
                    id++;
                    order++;
                })
                setTimezoneList(defaultTimezoneList)
    
                return defaultTimezoneList
            }
        
        }
    }
    // initializes the url parameters
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        // get the url params and assemble it for the timezone
            const urlParams = new URLSearchParams(window.location.search);

            if(urlParams.size > 0){
                const getTimezoneParams = urlParams.getAll("timezone")
               
                if(getTimezoneParams.length === 0)  initDefaultTimezoneList();
                else{
                    const getSplitValues = getTimezoneParams.map(item=>{
                        const getPairs = item.split(",");
                        return getPairs;
                    })
                    let getCustomTimezoneList=[]
                    let filteredKeys = []
                    let id = 1;
                    let order= 0;
                    getSplitValues.map(item=>{
                        
                        const getKey = parseInt(item[0]);
                        const getTimezone = allTimezonesQuery.allTimezonesJson.edges.filter(timezone=>timezone.node.key === getKey)
                        if(getTimezone.length > 0){
                            getCustomTimezoneList.push({id: id, order: order, key: getKey, name:item[1], timezone: getTimezone[0].node})
                            filteredKeys.push(Number(item[0]))
                            id++;
                            order++;
                        }
                       
                    })
                    const sorted = sortByOrder(getCustomTimezoneList)
                    setTimezoneList(sorted)
                }
              
            }
            else if(urlParams.size === 0){
               initDefaultTimezoneList();
            }
        
        }
    }, [])

    // construct the default timezone w/o url parameters 
    const initDefaultTimezoneList = ()=>{
        let defaultTimezoneList=[]
        let id = 1;
        let order= 0;

        const getFilteredTimezones = allTimezonesQuery.allTimezonesJson.edges.filter(item=>{
            const node = item.node.key;
            const findFilteredKeys = defaultFilteredKeys.find(x=>x.key === node) 
            if(findFilteredKeys)return item
            
        })
       
        const sorted = sortByOffset( getFilteredTimezones,true)
     
        sorted.map(item=>{
            const getCustomName = defaultFilteredKeys.find(x=>x.key === item.node.key).name;
            defaultTimezoneList.push({id: id, order: order, key: item.node.key, name:getCustomName?getCustomName:"", timezone: item.node})
            id++;
            order++;
        })
        setTimezoneList(defaultTimezoneList)
    }
    
   
    function getTimeSelected (isResize = false){
        // time display should increment by 15 mins 0:00 -> 0:15 -> 0:30 -> 0:45 -> 1:00
        //4 x 24 hrs = 96
        // e.g width = 100
        // 100/96 = 1.041%
        // 0:00 = 0
        // 0:15 = 1.04166666667
        const section = GetSection(zoneWidth);

        // contains the different positions every 15 mins
        const sections = [];
        sectionTime=[];
      
        for (let counter=0; counter <= 288; counter++){
            // assemble the pixel position and time per position
            var barTime = new Date(selectedDate);
            barTime.setDate(barTime.getDate()- 1);
            barTime.setHours(0,0,0,0);

            const mins = counter * 15;
            const position = section * counter;

            barTime.setMinutes(barTime.getMinutes()+ mins);
            sections.push(counter*section);
            sectionTime.push({id:counter+1, position: position, time: barTime});
          
        }

        if(positionSelected && isResize){
            console.log(positionSelected)
            const parsedTime = []
            sectionTime.map(section=>{
                parsedTime.push(Date.parse(section.time))
            })
        
            // const getNearestDateTime = nearestSection(parsedTime, Date.parse(new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000))); 
            // const nearestDateTime = new Date(getNearestDateTime);
        
            const getPosition = sectionTime.find((item) => {
                return item.id === positionSelected.id;
            })
        
            return {id: getPosition.id, position: getPosition.position, time: getPosition.time } ;
        }
        // if mouseposition is null/undefined move the selector to the nearest value of the current time
        if(mousePosition === undefined){
          
            //var now = new Date();
          
            const parsedTime = []
                sectionTime.map(section=>{
                parsedTime.push(Date.parse(section.time))
            })
        
            const getNearestDateTime = nearestSection(parsedTime, Date.parse(new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000))); 
            const nearestDateTime = new Date(getNearestDateTime);
        
            const getPosition = sectionTime.find((item) => {
                return Date.parse(item.time) === Date.parse(nearestDateTime)
            })
           
            return {id: getPosition.id, position: getPosition.position, time: getPosition.time } ;
        }
        else{
            // find the nearest pixel on mouse click/drag
            const getNearestValue = nearestSection(sections, mousePosition.x); 

            const getPosition = sectionTime.find((item) => {
                return item.position === getNearestValue;
            })
            console.log(getPosition)
            // selectedIdPosition(getPosition.id)
            setTimeMouseSelected(getPosition.time)
            const getSelectedTime = new Date( getPosition.time)
            // getSelectedTime.setHours(getSelectedTime.getHours - getSelectedTime.getTimezoneOffset())
            console.log(getSelectedTime)
            console.log(getSelectedTime.getTimezoneOffset())
            // setTimeMouseSelected(getSelectedTime)
            return {id: getPosition.id, position: getPosition.position, time: getPosition.time.toISOString() } ;
        }

        
    }


    // sets the line position when the mouse is clicked and dragged
    const sliderOnMouseMove=(event)=>
    {
        if(isMouseDown){
            setPositionSelected( getTimeSelected());
        }
    }


    // sets the line position when the mouse is clicked
    const sliderOnMouseDown=(event)=>
    {   
        setIsMouseDown(true);
        setPositionSelected( getTimeSelected());
    }

    // disables the dragging of the line when the mouse is released
    const sliderOnMouseUp=(event)=>{
        setIsMouseDown(false)
    }

    
    
    useEffect(() => {

        // this is for building the dropdown list
        let filterItems = []
        allTimezonesQuery.allTimezonesJson.edges.map(items=>{
            filterItems.push({value: items.node.key, label: items.node.text })
        })
        
        if (typeof window !== 'undefined') {
            // Records the position of the mouse
            const handleMouseMove = (e) => {
                let x =0;
                let y = 0;
                if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
                    var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
                    var touch = evt.touches[0] || evt.changedTouches[0];
                    x = touch.pageX;
                    y = touch.pageY;
                } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
                    x = e.clientX;
                    y = e.clientY;
                }
                setMousePosition({ x: x, y: y });
            };

            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('touchmove', handleMouseMove);
            // Get the current date/time
            // to display time: date.toLocaleTimeString()
            // to display date: date.toLocaleDateString("en-US", timeOptions)
            var timer = setInterval(()=> setCurrentDate(new Date()), 1000)

            return () => {
                // remove the event listeners
                function cleanup(){
                    clearInterval(timer)
                }

                window.removeEventListener(
                    'mousemove',
                    handleMouseMove
                );
                window.removeEventListener(
                    'touchmove',
                    handleMouseMove
                );
            };
        }
    }, []);

    const datePickerOnChange=(event)=>{
        event === null ? setSelectedDate(currrentDate) : setSelectedDate(event);
    }
    
    const modifyDayOnClick=(modifier)=>{
        let modifiedDate = new Date(selectedDate);
        modifiedDate.setDate(selectedDate.getDate() + modifier);
        setSelectedDate(modifiedDate)
    }  

    const modifyWeekOnClick=(modifier)=>{
        let modifiedDate = new Date(selectedDate);
        modifiedDate.setDate(selectedDate.getDate() + modifier);
        setSelectedDate(modifiedDate)
    }   

    const modifyMonthOnClick=(modifier)=>{
        let modifiedDate = new Date(selectedDate);
        modifiedDate.setMonth(selectedDate.getMonth() + modifier);
        setSelectedDate(modifiedDate)
    }   

  
    // wrapper function to hide the modal
    const wrapperCloseGeneratorModal = useCallback(val => {
        setShowGeneratorModal(false);
        setZoneHeight(zoneRef.current.offsetHeight);
    }, [setShowGeneratorModal]);

    const wrapperSetTimezoneList  = useCallback((updatedTimezoneItem) =>{
        setTimezoneList(updatedTimezoneItem);

    }, [setTimezoneList])

    const wrapperResetTimezoneList = useCallback(()=>{
        
      return DefaultTimezoneList();
    })

    const handleZoneResize=()=>{
        setZoneWidth(zoneRef.current.offsetWidth);
        setZoneTop(zoneRef.current.offsetTop);
        setZoneHeight(zoneRef.current.offsetHeight);

        // initialize the line position to be the current date/time
        if(mousePosition === undefined){
            setPositionSelected(getTimeSelected(true))
        }
    }


  return (
    <div draggable="false"  onMouseEnter={(event)=>sliderOnMouseUp(event)} className="zones-content has-text-centered is-size-8  " >
        <TimezoneUrlGeneratorModal   allTimezonesQueryEdges={allTimezonesQueryEdges} wrapperResetTimezoneList={wrapperResetTimezoneList} wrapperSetTimezoneList={wrapperSetTimezoneList} timezoneList={timezoneList} modalState={showGeneratorModal} closeGeneratorModalStateSetter={wrapperCloseGeneratorModal}/> 
        <div className="content" onMouseEnter={(event)=>sliderOnMouseUp(event)}>
            <br></br>

                <button className="button is-responsive timezone-button" onClick={()=>{modifyMonthOnClick(-1)}} >&lt; Month</button>
                <button className="button is-responsive timezone-button" onClick={()=>{modifyWeekOnClick(-7)}} >&lt; Week</button>
                <button className="button is-responsive timezone-button" onClick={()=>{modifyDayOnClick(-1)}}>&lt; Day</button>
                <DatePicker className="date-picker" format="yyyy-MM-dd" onChange={(event)=>{datePickerOnChange(event)}}  value={selectedDate}/>
                <button className="button is-responsive timezone-button" onClick={()=>{modifyDayOnClick(1)}}> Day &gt;</button>
                <button className="button is-responsive timezone-button" onClick={()=>{modifyWeekOnClick(7)}}> Week &gt;</button>
                <button className="button is-responsive timezone-button" onClick={()=>{modifyMonthOnClick(1)}} > Month &gt;</button>
        </div>
        <button className="button is-responsive timezone-button" onClick={()=>{setShowGeneratorModal(!showGeneratorModal)}} >URL Generator</button>


        <div className="zone-line-selector"  onTouchStart={(event)=>{sliderOnMouseDown(event)}} onTouchMove={(event)=>sliderOnMouseMove(event)} onTouchEnd={(event)=>sliderOnMouseUp(event)} onMouseMove={(event)=>sliderOnMouseMove(event)}  onMouseUp={(event)=>sliderOnMouseUp(event)} onMouseDown={(event)=>{sliderOnMouseDown(event)}} style={{top: zoneTop, left:  positionSelected.position ? positionSelected.position : 0, height: zoneHeight}}  ></div>
        
        <div className="zone disable-text-selection" draggable="false" onResize={()=>{handleZoneResize()}} ref={zoneRef} onTouchStart={(event)=>{sliderOnMouseDown(event)}} onTouchMove={(event)=>sliderOnMouseMove(event)} onTouchEnd={(event)=>sliderOnMouseUp(event)} onMouseMove={(event)=>sliderOnMouseMove(event)} onMouseUp={(event)=>sliderOnMouseUp(event)} onMouseDown={(event)=>{sliderOnMouseDown(event)}} style={{align: "center"}} >            
            {timezoneList ? <ZoneDates zoneWidth={zoneWidth} timeSelected={positionSelected.time} timezones={timezoneList} selectedDate={selectedDate}></ZoneDates> : null}
            
            <br></br>
        </div>

    </div>
  );
};

export default TimezoneConverter;